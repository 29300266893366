import {FadeInView} from '@/components/basic/animations';
import React, {useState} from 'react';
import HomeLotteryHot from './lottery-hot';
import HomeOtherGames from './other-games';
import HomeLotteryDigit from './lottery-digit';
import HomeLotteryKerala from './lottery-kerala';
import HomeLotteryMix from './lottery-mix';
import HomeLotterySatta from './lottery-satta';
import homeStore from '../../home.refresh.service';
import {
  LotteryData,
  getHomeGameList,
  HomeGameFloorItem,
  getHomeHotGames,
} from '../../home.service';

const HomeHot = () => {
  const [floors, setFloors] = useState<HomeGameFloorItem[]>([]);
  const [games, setGames] = useState<LotteryData>();

  const getGameList = async () => {
    try {
      const r = await getHomeHotGames();
      setFloors(r);
      getHomeGameList().then(res => {
        if (res) {
          setGames(res);
        }
      });
    } catch (e) {
      console.log('home hot error: ', e);
    } finally {
      homeStore.refreshEnd.next();
    }
  };
  React.useEffect(() => {
    getGameList();
    const sub = homeStore.refresh.subscribe(() => {
      getGameList();
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return (
    <FadeInView
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: 'auto',
        },
      ]}>
      {games ? (
        !!floors.length &&
        floors.map((item, index) => (
          <div key={index}>
            {item.uniqueKey === 'HotGmae' && (
              <HomeLotteryHot data={item.gamesList} />
            )}
            {item.gamesList &&
              item.gamesList.length &&
              item.uniqueKey === '3DigitGame' && (
                <HomeLotteryDigit
                  serverTime={item.currentTimestamp || games.currentTimestamp}
                  // @ts-ignore
                  list={item.gamesList}
                  batchList={games.pickResList}
                />
              )}
            {item.gamesList &&
              item.gamesList.length &&
              item.uniqueKey === 'StateLottery' && (
                <HomeLotteryMix
                  serverTime={item.currentTimestamp || games.currentTimestamp}
                  // @ts-ignore
                  list={item.gamesList}
                  batchList={games.stateLotteryList}
                />
              )}
            {item.gamesList &&
              item.gamesList.length &&
              item.uniqueKey === 'KeralaLottery' && (
                <HomeLotteryKerala
                  serverTime={item.currentTimestamp || games.currentTimestamp}
                  // @ts-ignore
                  list={item.gamesList || []}
                  batchList={games.keralaResList}
                />
              )}
            {item.gamesList &&
              item.gamesList.length &&
              item.uniqueKey === 'SattaMatka' && (
                <HomeLotterySatta
                  serverTime={item.currentTimestamp || games.currentTimestamp}
                  // @ts-ignore
                  list={item.gamesList}
                  batchList={games.matkaResList}
                />
              )}
            {item.gamesList &&
              item.gamesList.length &&
              ![
                'HotGmae',
                '3DigitGame',
                'StateLottery',
                'KeralaLottery',
                'SattaMatka',
              ].includes(item.uniqueKey) && <HomeOtherGames data={item} />}
          </div>
        ))
      ) : (
        <></>
      )}
      {/* {games ? (
        <>
          <HomeLotteryHot />
          <HomeLotteryDigit
            serverTime={games.currentTimestamp}
            list={games.pickResList}
          />
          <HomeLotteryMix
            serverTime={games.currentTimestamp}
            list={games.stateLotteryList}
          />
          <HomeLotteryKerala
            serverTime={games.currentTimestamp}
            list={games.keralaResList}
          />
          <HomeLotterySatta
            serverTime={games.currentTimestamp}
            list={games.matkaResList}
          />
        </>
      ) : (
        <></>
      )} */}
    </FadeInView>
  );
};

export default HomeHot;
