import Text from '@basicComponents/text';
import React, {useCallback, useState} from 'react';
import {View, StyleSheet, ImageBackground, Image} from 'react-native';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {goTo} from '@/utils';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {combineLatest} from 'rxjs';
import {MessageCountInfo, postUserInfo} from '@services/global.service';
import {useTranslation} from 'react-i18next';
import LazyImage, {ImageUrlType} from '@/components/basic/image';
import {useFocusEffect} from '@react-navigation/native';
import {emptyHeaderImg} from '@/pages/me/me.variable';
import Price from '@/components/business/price';
const defaultHeaderImg = require('@components/assets/icons/default-header.webp');

const HomeHeader = (props: {
  setMenuOpen: (bool: boolean, unreadCount?: number) => void;
  version: number;
}) => {
  const {i18n} = useTranslation();
  const {setMenuOpen} = props;
  const [showLogin, setShowLogin] = React.useState(false);
  const [showUser, setShowUser] = React.useState(false);
  const [userName, setUserName] = React.useState<string>();
  const [userHeader, setUserHeader] =
    React.useState<ImageUrlType>(defaultHeaderImg);
  const [amount, setAmount] = React.useState<number>(0);
  const styles = StyleSheet.create({
    bellTipIcon: {
      position: 'absolute',
      top: 4,
      right: 4,
    },
    username: {
      marginBottom: -theme.paddingSize.xxs / 2,
      textAlign: 'right',
    },
  });
  const [messageCountInfo, setMessageCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });

  const init = useCallback(() => {
    const sub = combineLatest([
      globalStore.tokenSubject,
      globalStore.globalLoading,
    ]).subscribe(([t, l]) => {
      // 没有token且没有加载时,显示login按钮
      setShowLogin(!t && !l);
      setShowUser(!!t);
      if (t) {
        postUserInfo().then(res => {
          globalStore.userInfo = res;
          setUserName(res.userName || res.userPhone);
          setUserHeader(res.userAvatar);
          globalStore.setAmount(res.userBalance);
        });
      }
    });

    const amountSub = globalStore.amountChanged.subscribe(res => {
      if (res.current) {
        setAmount(res.current);
      }
    });
    const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {
      setMessageCountInfo(
        _countInfo || {
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        },
      );
    });
    return () => {
      sub.unsubscribe();
      // sub2.unsubscribe();
      amountSub.unsubscribe();
      msgSub.unsubscribe();
    };
  }, []);
  useFocusEffect(init);
  return (
    <DetailNavTitle
      showProgress
      containerStyle={[
        theme.flex.row,
        theme.flex.centerByCol,
        {
          paddingTop: theme.paddingSize.zorro,
          paddingBottom: theme.paddingSize.zorro,
        },
      ]}
      hideServer
      leftNode={
        <NativeTouchableOpacity
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={() =>
            setMenuOpen(true, messageCountInfo?.messageTotalCount)
          }>
          <Image
            style={[theme.icon.l, theme.margin.rightxxs]}
            source={require('@assets/icons/menu.webp')}
          />
          <Text size="medium" fontFamily="fontInterBold" second calc>
            {i18n.t('home.label.menu')}
          </Text>
        </NativeTouchableOpacity>
      }
      hideAmount
      rightNode={
        <View style={[theme.flex.centerByCol, theme.flex.row]}>
          {showLogin && (
            <View
              style={[
                theme.flex.centerByCol,
                theme.flex.row,
                {
                  marginLeft: theme.paddingSize.xl + theme.paddingSize.m,
                },
              ]}>
              <Button
                size="small"
                type="linear-primary"
                titleBold={true}
                title={i18n.t('me.user.loginUpper')}
                onPress={() => {
                  goTo('Login');
                }}
              />
            </View>
          )}
          {showUser && (
            <NativeTouchableOpacity
              onPress={() => {
                goTo('Notification');
              }}
              style={[
                theme.padding.s,
                theme.position.rel,
                {
                  marginRight: -theme.paddingSize.s,
                },
              ]}>
              <Image
                style={[theme.icon.l]}
                source={require('@assets/icons/home/notifications.webp')}
              />
              {messageCountInfo?.messageTotalCount > 0 && (
                <ImageBackground
                  style={[theme.icon.m, styles.bellTipIcon]}
                  source={require('@assets/icons/home/green-tip.webp')}
                />
              )}
            </NativeTouchableOpacity>
          )}
          {showUser && (
            <NativeTouchableOpacity
              onPress={() => goTo('Me')}
              style={[
                theme.flex.centerByCol,
                theme.flex.row,
                {
                  marginLeft: theme.paddingSize.xl + theme.paddingSize.m,
                },
              ]}>
              <View style={[theme.flex.col]}>
                <Text main style={[styles.username]} textAlign="right" calc>
                  {userName || ''}
                </Text>
                <Price
                  textProps={{
                    calc: true,
                    main: true,
                    size: 'medium',
                  }}
                  style={[theme.flex.end]}
                  suffixUnit="K"
                  spacing
                  price={amount}
                />
              </View>
              <View style={[theme.margin.lefts]}>
                <LazyImage
                  imageUrl={
                    globalStore.token
                      ? userHeader
                        ? userHeader
                        : defaultHeaderImg
                      : emptyHeaderImg
                  }
                  width={theme.iconSize.xxl}
                  height={theme.iconSize.xxl}
                  radius={theme.iconSize.xxl / 2}
                />
              </View>
            </NativeTouchableOpacity>
          )}
        </View>
      }>
      <View style={[theme.flex.flex1]} />
    </DetailNavTitle>
  );
};

export default HomeHeader;
