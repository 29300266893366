import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {goTo, getGameRates} from '@/utils';
import {toGame} from '@/common-pages/game-navigate';
import './hot.css';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import {CarListItem} from '../../home.type';
import {getCarGameList} from '../../home.service';

const ConerRate = ({rate}: {rate: string}) => (
  <div className="coner-rate">
    <div className="coner-rate-text">
      rate <span className="coner-rate-rate">{`${rate}%`}</span>
    </div>
  </div>
);

const AnimatedAvatars = ({avatars, i}: {avatars: string[][]; i: number}) => {
  const [visibleAvatars, setVisibleAvatars] = useState<string[]>([]);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      // Wait for exit animation to complete
      setTimeout(() => {
        const [first, ...rest] = avatars[i];
        setVisibleAvatars([...rest, first]);
      }, 300);
      // Reset animation state after both animations complete
      setTimeout(() => {
        setIsAnimating(false);
      }, 300);
    }, 2000);
    return () => clearInterval(interval);
  }, [avatars, i]);

  return (
    <div className="avatars-container">
      <div className="avatars-wrapper">
        {visibleAvatars.map((item, index) => (
          <div
            key={`${item}-${index}`}
            className={`avatar-item ${
              isAnimating ? 'animating' : index === 3 ? 'avatar-show' : ''
            } ${index > 3 && 'avatar-hidden'}`}
            style={{
              zIndex: 5 - index,
            }}>
            <img
              src={require(`@assets/imgs/home/avtars/${item}.webp`)}
              alt={`avatar-${i}-${item}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const HomeLotteryHot = ({data}: {data: any}) => {
  const i18n = useTranslation();
  const [avatars, setAvatars] = useState([
    [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
    ],
    [
      '15',
      '14',
      '13',
      '12',
      '11',
      '10',
      '9',
      '8',
      '7',
      '6',
      '5',
      '4',
      '3',
      '2',
      '1',
    ],
  ]);
  const [car, setCar] = useState<CarListItem>();
  const getCar = () => {
    getCarGameList().then(res => {
      if (res) {
        setCar(res.content[0]);
      }
    });
  };
  useEffect(() => {
    getCar();
    const interval = setInterval(() => {
      setAvatars(([prevAvatar1, prevAvatar2]) => {
        const newAvatars = [[...prevAvatar1], [...prevAvatar2]];
        return newAvatars.map(avatar => {
          const newAvatar = [...avatar];
          const _avatar = newAvatar.shift();
          newAvatar.push(_avatar!);
          return newAvatar;
        });
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="hot-title-wrap">
        <div className="hot-title-bg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="40"
            viewBox="0 0 200 40"
            fill="none">
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0H151.716C157.02 0 162.107 2.10714 165.858 5.85786L200 40H0V8Z"
              fill="url(#paint0_linear_14612_33527)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_14612_33527"
                x1="80"
                y1="0"
                x2="80"
                y2="40"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="#EFF1F7" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="title-left">
          <div className="title-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none">
              <rect width="6" height="6" fill="#C7812E" />
              <g filter="url(#filter0_b_14612_33529)">
                <rect
                  x="2"
                  y="2"
                  width="6"
                  height="6"
                  fill="#2EC7A2"
                  fillOpacity="0.5"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_14612_33529"
                  x="1"
                  y="1"
                  width="8"
                  height="8"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_14612_33529"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_14612_33529"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <span className="left-text">{i18n.t('home.hotgame.title')}</span>
        </div>
        <span className="title-right">{i18n.t('home.hotgame.titleRight')}</span>
      </div>
      <div className="hot-content">
        <div className="dice-color">
          <ExposureWrap message="HOME_HOT_DICE_EXPOSURE">
            <div
              className="dice-card"
              onClick={() => {
                trackClick('HOME_HOT_DICE_TAB');
                goTo('Dice');
              }}>
              <ConerRate rate={getGameRates('DICE')} />
              <img
                src={require('@assets/imgs/home/dice.webp')}
                alt="dice-icon"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '0.5rem',
                }}
              />
            </div>
          </ExposureWrap>
          <ExposureWrap message="HOME_HOT_COLOR_EXPOSURE">
            <div
              className="color-card"
              onClick={() => {
                trackClick('HOME_HOT_COLOR_TAB');
                goTo('Color');
              }}>
              <ConerRate rate={getGameRates('COLOR')} />
              <img
                src={require('@assets/imgs/home/color.webp')}
                alt="color-icon"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '0.5rem',
                }}
              />
            </div>
          </ExposureWrap>
        </div>
        <ExposureWrap message="HOME_HOT_QUICK3D_EXPOSURE">
          <div
            className="quick-3d"
            onClick={() => {
              trackClick('HOME_HOT_QUICK3D_TAB');
              goTo('Digit', {
                pickGameType: 'quick',
              });
            }}>
            <ConerRate rate={getGameRates('Quick')} />
            <img
              src={require('@assets/imgs/home/quick3d.webp')}
              alt="quick-icon"
              style={{
                width: '21.9375rem',
                height: '6.9375rem',
                objectFit: 'contain',
                borderRadius: '0.5rem',
              }}
            />
            <div className="avatars-wrap">
              <AnimatedAvatars avatars={avatars} i={0} />
              <span className="bottom-numbers">{`${data?.[2]?.playersNumber} Winners`}</span>
            </div>
          </div>
        </ExposureWrap>
        <ExposureWrap message="HOME_HOT_RACECAR_EXPOSURE">
          <div
            className="race-car"
            onClick={() => {
              trackClick('HOME_HOT_RACECAR_TAB');
              car && toGame(car);
            }}>
            <ConerRate rate={getGameRates('Racecar')} />
            <img
              src={require('@assets/imgs/home/race-car.webp')}
              alt="car-icon"
              style={{
                width: '21.9375rem',
                height: '6.9375rem',
                objectFit: 'contain',
                borderRadius: '0.5rem',
              }}
            />
            <div className="avatars-wrap">
              <AnimatedAvatars avatars={avatars} i={1} />
              <span className="bottom-numbers">{`${data?.[3]?.playersNumber} Winners`}</span>
            </div>
          </div>
        </ExposureWrap>
      </div>
    </>
  );
};

export default React.memo(HomeLotteryHot);
