import React from 'react';
import HomeFloorBox from '../home-floor-box';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LazyImage from '@/components/basic/image';
import {useResponsiveDimensions} from '@/utils';
import {SafeAny} from '@/types';
import {CarListItem} from '../../home.type';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import {UserPlay} from '@/components/business/user-play/user-play';
// import CountDown from '../count-down';
// import {View, Image} from 'react-native';
// import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
// const bgs = [
//   ['#FD3', '#FFEDC0'],
//   ['#26BDFF', '#B9FBFF'],
//   ['#FF4343', '#FFD8B4'],
// ];
export interface HomeLotteryCarTemplateProps {
  /** 为null或者undefined不显示,为0显示灰色 */
  list: (CarListItem & {cycle: number; remain: number})[];
  onPress: (v: CarListItem) => void;
  title: string;
  titleColor: string;
  peopleObj?: SafeAny;
  currentTime?: number;
  serverTime?: number;
}
const HomeLotteryCarTemplate = ({
  list,
  onPress,
  title,
  titleColor,
}: HomeLotteryCarTemplateProps) => {
  const {width} = useResponsiveDimensions();
  // const imageSize = (theme.imageSize.m / 375) * width;
  const onlineUser = list.reduce((res, cur) => {
    res += cur.playersNumber;
    return res;
  }, 0);
  // const serverTime = list[0]?.currentTimestamp;

  return (
    <HomeFloorBox
      title={title}
      list={list}
      color={titleColor}
      hasRightPadding={false}
      rightDom={<UserPlay number={onlineUser} />}
      // rightDom={
      //   <Text calc blod fontSize={10} second>
      //     {onlineUser < 9999
      //       ? i18n.t('home.label.onlineUser', {number: onlineUser})
      //       : i18n.t('home.label.popularGame')}
      //   </Text>
      // }
      renderItem={(item, index) => {
        // const cycle = +item.gameUrl.split('cycle=')[1].split('&')[0];
        return (
          <ExposureWrap key={index} message={`HOME_CAR_${index + 1}_EXPOSURE`}>
            <NativeTouchableOpacity
              style={[
                {
                  width: (113 / 375) * width,
                },
                theme.background.palegrey,
                theme.borderRadius.m,
                theme.overflow.hidden,
                index !== 0 && theme.margin.leftxs,
              ]}
              onPress={() => {
                trackClick(`HOME_CAR_${index + 1}_TAB`);
                onPress(item);
              }}>
              <LazyImage
                // imageUrl={item.gamePic}
                imageUrl={item.otherUrl}
                occupancy="transparent"
                width={(113 / 375) * width}
                height={(133 / 375) * width}
              />
            </NativeTouchableOpacity>
          </ExposureWrap>
          // <NativeTouchableOpacity
          //   style={[
          //     {
          //       width: (113 / 375) * width,
          //     },
          //     theme.background.palegrey,
          //     theme.borderRadius.m,
          //     theme.overflow.hidden,
          //     index !== 0 && theme.margin.leftxs,
          //   ]}
          //   onPress={() => onPress(item)}
          //   key={index}>
          //   <LinearGradient
          //     style={[
          //       theme.fill.fill,
          //       theme.borderRadius.m,
          //       theme.padding.tbs,
          //       theme.flex.centerByCol,
          //       theme.flex.between,
          //     ]}
          //     start={{x: 1, y: 0}}
          //     end={{x: 1, y: 1}}
          //     colors={[bgs[index][0], bgs[index][1]]}>
          //     <Image
          //       source={require('@assets/imgs/home/lottery-img-light-bg.webp')}
          //       style={[
          //         // eslint-disable-next-line react-native/no-inline-styles
          //         {
          //           left: 0,
          //           top: 0,
          //           right: 0,
          //           bottom: 0,
          //           position: 'absolute',
          //         },
          //       ]}
          //     />
          //     <LazyImage
          //       imageUrl={item.otherUrl}
          //       width={64}
          //       height={64}
          //       occupancy="transparent"
          //     />
          //     <View
          //       style={[
          //         {gap: theme.paddingSize.xxs},
          //         theme.flex.centerByCol,
          //         theme.margin.topxxs,
          //       ]}>
          //       <Text
          //         calc
          //         fontFamily="fontInterBold"
          //         color={theme.fontColor.main}
          //         fontSize={12}>
          //         {cycle} min
          //       </Text>
          //       <Text calc fontSize={9}>
          //         {i18n.t('home.car.text')}
          //       </Text>
          //       <CountDown
          //         dotColor="black"
          //         resetTime={cycle * 60}
          //         serverTime={item.currentTimestamp}
          //         remain={item.remain}
          //       />
          //     </View>
          //   </LinearGradient>
          // </NativeTouchableOpacity>
        );
      }}
    />
  );
};

export default React.memo(HomeLotteryCarTemplate);
