import Animated, {
  useSharedValue,
  withTiming,
  Easing,
  useAnimatedStyle,
  withRepeat,
  withDelay,
  withSequence,
} from 'react-native-reanimated';
import {WinnerItem} from '../home.type';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {LayoutChangeEvent, StyleSheet, View} from 'react-native';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {toGame} from '@/common-pages/game-navigate';
import LazyImage from '@/components/basic/image';
import Text from '@/components/basic/text';
import {toPriceStr} from '@/utils';

export interface HomeAnimatedTopProps {
  list: (WinnerItem | null)[];
}

const HomeAnimatedTop: React.FC<HomeAnimatedTopProps> = ({list}) => {
  const style = StyleSheet.create({
    bigwinScrollview: {
      height: 296,
    },
  });
  const transY = useSharedValue(0);
  const [outHeight, setOutHeight] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);
  const maxTop = useMemo(() => {
    return innerHeight - outHeight;
  }, [outHeight, innerHeight]);

  const doAnimate = useCallback(() => {
    transY.value = withRepeat(
      withSequence(
        withTiming(-maxTop, {
          duration: 9000,
          easing: Easing.linear,
        }),
        withDelay(4000, withTiming(-outHeight, {duration: 0})),
      ),
      0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxTop, outHeight]);

  useEffect(doAnimate, [doAnimate]);

  const handleViewLayout = (e: LayoutChangeEvent) => {
    setOutHeight(e.nativeEvent.layout.height);
  };

  const handleInnerViewLayout = (e: LayoutChangeEvent) => {
    setInnerHeight(e.nativeEvent.layout.height);
  };

  const topAnimStyle = useAnimatedStyle(() => {
    return {
      transform: [{translateY: transY.value}],
    };
  }, [transY]);

  const handleToGame = (item?: WinnerItem | null) => {
    if (!item) {
      return;
    }
    toGame({
      source: item.source,
      name: item.gameName,
      gameUrl: item.gameUrl,
      id: item.gamesId,
      tripartiteUniqueness: item.tripartiteUniqueness,
      type: item.gameType,
    });
  };
  return (
    <View
      style={[style.bigwinScrollview, theme.flex.col, theme.overflow.hidden]}
      onLayout={handleViewLayout}>
      <Animated.View
        style={[theme.flex.col, topAnimStyle]}
        onLayout={handleInnerViewLayout}>
        {list.map((item, itemi) => {
          return (
            <NativeTouchableOpacity
              activeOpacity={item ? 0.8 : 1}
              key={itemi}
              style={[
                theme.padding.lrm,
                itemi === 0 ? theme.margin.tops : theme.margin.topl,
                theme.flex.row,
                theme.flex.centerByCol,
              ]}
              onPress={() => handleToGame(item)}>
              {!item ? (
                <View style={[theme.image.s, theme.background.palegrey]} />
              ) : (
                <LazyImage
                  imageUrl={item.gamePic}
                  width={theme.imageSize.s}
                  height={theme.imageSize.s}
                  radius={4}
                  resizeMode="cover"
                />
              )}
              <View
                style={[
                  theme.margin.lefts,
                  theme.flex.flex1,
                  theme.flex.centerByRow,
                ]}>
                {!item ? (
                  <>
                    <View
                      style={[
                        // eslint-disable-next-line react-native/no-inline-styles
                        {
                          height: theme.fontSize.s,
                          width: '70%',
                        },
                        theme.background.palegrey,
                      ]}
                    />
                    <View
                      style={[
                        // eslint-disable-next-line react-native/no-inline-styles
                        {
                          height: theme.fontSize.s,
                          width: '60%',
                        },
                        theme.background.palegrey,
                        theme.margin.tbxxs,
                      ]}
                    />
                    <View
                      style={[
                        // eslint-disable-next-line react-native/no-inline-styles
                        {
                          height: theme.fontSize.s,
                          width: '85%',
                        },
                        theme.background.palegrey,
                      ]}
                    />
                  </>
                ) : (
                  <>
                    <Text second calc>
                      {item.userName}
                    </Text>
                    <Text second numberOfLines={1} ellipsizeMode="tail" calc>
                      {item.gameName}
                    </Text>
                    <Text
                      color={theme.basicColor.red}
                      calc
                      fontFamily="fontInterBold">
                      {toPriceStr(+item.bonus)}
                    </Text>
                  </>
                )}
              </View>
            </NativeTouchableOpacity>
          );
        })}
      </Animated.View>
    </View>
  );
};

export default HomeAnimatedTop;
