import React, {useMemo} from 'react';
import HomeFloorBox from '../home-floor-box';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LazyImage from '@/components/basic/image';
import globalStore from '@/services/global.state';
import {View} from 'react-native';
import {BasicObject} from '@/types';
import {useResponsiveDimensions, getNumberRates} from '@/utils';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import {UserPlay} from '@/components/business/user-play/user-play';
import {toGame, GameParams} from '@/common-pages/game-navigate';

const shadow = {
  startColor: 'rgba(0, 0, 0, 0.25)',
  distance: 4,
  offset: [-2, 2],
  style: [
    theme.fill.fillW,
    theme.padding.tbxs,
    theme.padding.lrs,
    theme.flex.center,
  ] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({boxShadow: '-2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset'});
}

const HomeOtherGames = ({
  data,
}: {
  data: {
    name: string;
    gamesList: BasicObject[];
  };
}) => {
  const list = useMemo(() => {
    if (data.gamesList?.length) {
      if (data.gamesList?.length > 4) {
        const halfNum = Math.ceil(data.gamesList.length / 2);
        const arr1 = data.gamesList;
        const arr2 = data.gamesList.splice(halfNum);
        return arr1.map((v, i) => [v, arr2[i]]);
      } else {
        return data.gamesList.map(v => [v]);
      }
    }
    return [[]];
  }, [data]);
  const {width} = useResponsiveDimensions();
  const gap = 6;
  const itemWidth = (width - gap * 6) / 3;

  return (
    <HomeFloorBox
      title={data.name}
      list={list}
      color="rgba(202, 195, 243, 0.50)"
      hasRightPadding={false}
      rightDom={<UserPlay number={getNumberRates('OTHERS')} />}
      renderItem={(arr: BasicObject[], index: number) => {
        return (
          <View key={index}>
            {arr?.map((item, itemIndex) => {
              return item === null || item === undefined ? (
                <View key={index + '' + itemIndex} />
              ) : (
                <ExposureWrap
                  key={index + '' + itemIndex}
                  message={`HOME_${data.name
                    .replace(/[\s\-\.]/g, '_')
                    .toUpperCase()}_${index + 1}_EXPOSURE`}>
                  <NativeTouchableOpacity
                    onPress={() => {
                      trackClick(
                        `HOME_${data.name
                          .replace(/[\s\-\.]/g, '_')
                          .toUpperCase()}_${index + 1}_TAB`,
                      );
                      toGame(item as GameParams);
                    }}
                    style={[
                      theme.borderRadius.m,
                      theme.overflow.hidden,
                      theme.margin.rightxs,
                      theme.margin.btms,
                      {
                        width: itemWidth,
                        maxWidth: itemWidth,
                      },
                    ]}>
                    {item ? (
                      <LazyImage
                        imageUrl={item.otherUrl || item.gamePic}
                        resizeMode="cover"
                        width={itemWidth}
                        height={(itemWidth * 148) / 112}
                      />
                    ) : (
                      <View
                        style={[
                          {
                            height: (itemWidth * 148) / 112,
                            width: itemWidth,
                          },
                          theme.background.palegrey,
                        ]}
                      />
                    )}
                  </NativeTouchableOpacity>
                </ExposureWrap>
              );
            })}
          </View>
        );
      }}
    />
  );
};

export default React.memo(HomeOtherGames);
