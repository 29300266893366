import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {downloadApk, stopBeforeDownload} from '@/utils';
import {inApp} from '@/utils/interface';
import React from 'react';
import {ImageBackground, Image} from 'react-native';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';

const Download: React.FC = () => {
  const [show, setShow] = React.useState(true);
  const {calcActualSize} = useScreenSize();
  const toDownload = () => {
    if (stopBeforeDownload()) {
      return;
    }
    downloadApk();
  };
  const bannerHeight = calcActualSize(56);
  const iconSize = calcActualSize(18);
  const margin = calcActualSize(theme.paddingSize.s);
  if (inApp) {
    return null;
  }
  return show ? (
    <ExposureWrap message="HOME_DOWNLOAD_TIPS_EXPOSURE">
      <ImageBackground
        resizeMode="cover"
        source={require('@assets/imgs/download.webp')}
        style={[
          theme.fill.fillW,
          theme.flex.row,
          theme.flex.centerByCol,
          {
            height: bannerHeight,
          },
        ]}>
        <NativeTouchableOpacity
          onPress={() => {
            trackClick('HOME_DOWNLOAD_DOWNLOADBUTTON_TAP');
            toDownload();
          }}
          style={[theme.flex.flex1, theme.fill.fillH]}
        />
        <NativeTouchableOpacity
          onPress={() => {
            trackClick('HOME_DOWNLOAD_CLOSEBUTTON_TAP');
            setShow(false);
          }}>
          <Image
            style={[
              {
                height: iconSize,
                width: iconSize,
                marginHorizontal: margin,
              },
            ]}
            source={require('@assets/icons/close-new.webp')}
          />
        </NativeTouchableOpacity>
      </ImageBackground>
    </ExposureWrap>
  ) : (
    <></>
  );
};

export default Download;
