import React from 'react';
import HomeFloorBox from '../home-floor-box';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import {View, Image} from 'react-native';
import {formatDate, useResponsiveDimensions} from '@/utils';
import {SafeAny} from '@/types';
import {DICE_GAME_ICON, COLOR_GAME_ICON} from '../../games';
import CountDown from '../count-down';
const lightbg = require('@assets/imgs/home/lottery-img-light-bg.webp');
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import {UserPlay} from '@/components/business/user-play/user-play';

export interface HomeLotteryImgTemplateProps<T> {
  /** 为null或者undefined不显示,为0显示灰色 */
  list: (T & {timer?: number; cycle: number})[];
  onPress: (v: T) => void;
  title: string;
  onlineUser: number;
  bgColor: string;
  odds: number;
  game?: string;
  titleColor: string;
  peopleObj?: SafeAny;
  serverTime?: number;
  currentTime?: number;
}

const getTimer = (time: number, cycle: number) => {
  const date = formatDate(time, 'yyyy-MM-dd');
  if (date === 'Invalid Date') {
    return 0;
  }
  return (
    cycle * 60 -
    Math.round(
      (Math.abs(time - new Date(date).getTime()) % (cycle * 60000)) / 1000,
    )
  );
};

const HomeLotteryImgTemplate = <T,>({
  list,
  onPress,
  title,
  onlineUser,
  bgColor,
  odds,
  game,
  titleColor,
  currentTime,
  serverTime,
}: HomeLotteryImgTemplateProps<T>) => {
  // const {width} = useResponsiveDimensions();
  // const imageSize = (theme.imageSize.m / 375) * width;

  // const sourceList = React.useMemo(() => {
  //   return game === 'dice' ? DICE_GAME_ICON : COLOR_GAME_ICON;
  // }, [game]);

  return (
    <HomeFloorBox
      title={title}
      list={list}
      color={titleColor}
      hasRightPadding={false}
      rightDom={<UserPlay number={onlineUser} />}
      // rightDom={
      //   <Text calc blod fontSize={10} second>
      //     {onlineUser < 9999
      //       ? i18n.t('home.label.onlineUser', {number: onlineUser})
      //       : i18n.t('home.label.popularGame')}
      //   </Text>
      // }
      renderItem={(item, index) => {
        return (
          <Item
            key={index}
            item={item}
            index={index}
            onPress={onPress}
            bgColor={bgColor}
            game={game}
            odds={odds}
            currentTime={currentTime}
            serverTime={serverTime}
            wrapWidth={list.length === 3 ? 113 : 104}
          />
        );
      }}
    />
  );
};

const Item = <T,>({
  item,
  index,
  bgColor,
  onPress,
  game,
  odds,
  currentTime,
  serverTime,
  wrapWidth = 104,
}: {
  index: number;
  serverTime?: number;
  currentTime?: number;
  bgColor: string;
  odds: number;
  game?: string;
  onPress: (v: T) => void;
  item: T & {timer?: number; cycle: number};
  wrapWidth?: number;
}) => {
  const {width} = useResponsiveDimensions();
  const imageSize = (theme.imageSize.m / 375) * width;
  const sourceList = React.useMemo(() => {
    return game === 'dice' ? DICE_GAME_ICON : COLOR_GAME_ICON;
  }, [game]);

  const remain = React.useMemo(() => {
    if (item.cycle && serverTime) {
      return getTimer(serverTime, item.cycle);
    }
  }, [item.cycle, serverTime]);

  return (
    <ExposureWrap message={`HOME_${game ? 'DICE' : 'COLOR'}_EXPOSURE`}>
      <NativeTouchableOpacity
        style={[
          {
            // height: (141 / 375) * width,
            width: (wrapWidth / 375) * width,
          },
          theme.background.palegrey,
          theme.borderRadius.m,
          index !== 0 && theme.margin.leftxs,
        ]}
        onPress={() => {
          trackClick(`HOME_${game ? 'DICE' : 'COLOR'}_EXPOSURE`);
          onPress(item);
        }}
        key={index}>
        <View
          style={[
            theme.fill.fill,
            theme.flex.center,
            theme.borderRadius.m,
            {
              backgroundColor: bgColor,
            },
          ]}>
          <View
            style={[
              theme.position.abs,
              {
                top: (12 / 375) * width,
                bottom: (28 / 375) * width,
              },
              theme.flex.center,
              theme.padding.tops,
            ]}>
            <Image
              source={lightbg}
              style={[
                {
                  width: (104 / 375) * width,
                  height: (137 / 375) * width,
                },
              ]}
            />
          </View>
          <View
            style={[
              theme.padding.topxs,
              theme.fill.fill,
              theme.flex.centerByCol,
              theme.flex.flex1,
              theme.flex.between,
              theme.position.rel,
            ]}>
            <Image
              style={[
                {
                  width: imageSize,
                  height: imageSize,
                },
              ]}
              source={sourceList[item.cycle]}
            />
            <View style={[theme.flex.centerByCol, theme.margin.tbxxs]}>
              <Text calc blod color={theme.basicColor.white}>
                {item.cycle} min
              </Text>
              <Text
                calc
                size="large"
                fontFamily="fontAnybody"
                color={theme.basicColor.white}>
                1 : {odds}
              </Text>
            </View>
            <View
              style={[
                theme.fill.fillW,
                theme.padding.tbxs,
                theme.flex.centerByCol,
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  backgroundColor: '#FFFFFF1A',
                },
              ]}>
              <CountDown
                resetTime={item.cycle * 60}
                currentTime={currentTime}
                remain={remain}
                serverTime={serverTime}
              />
            </View>
          </View>
        </View>
      </NativeTouchableOpacity>
    </ExposureWrap>
  );
};

export default React.memo(HomeLotteryImgTemplate);
