import React from 'react';
import {StateLotteryItem} from '../../home.type';
import globalStore from '@/services/global.state';
import {
  formatDate,
  getUrlParams,
  goTo,
  toPriceStr,
  useResponsiveDimensions,
  getNumberRates,
} from '@/utils';
import {useTranslation} from 'react-i18next';
import {View, Image} from 'react-native';
import theme from '@/style';
import LazyImage from '@/components/basic/image';
import Text from '@/components/basic/text';
// import {DIGIT_GAME_ICON} from '../../games';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import HomeFloorBox from '../home-floor-box';
import Price from '@/components/business/price';
// import ClockIcon from './clock';
import {SafeAny} from '@/types';
import CountDown from '../count-down';
import dayjs from 'dayjs';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import {UserPlay} from '@/components/business/user-play/user-play';

type HomeMixDetail = StateLotteryItem & {
  sellAmount: string;
  result: string[];
  isQuick?: boolean;
};

interface LotteryMixProps {
  list: StateLotteryItem[];
  serverTime?: number;
}

const getTimer = (time: number, cycle: number) => {
  const date = formatDate(time, 'yyyy-MM-dd');
  if (date === 'Invalid Date') {
    return 0;
  }
  return (
    cycle * 60 -
    Math.round(
      (Math.abs(time - new Date(date).getTime()) % (cycle * 60000)) / 1000,
    )
  );
};

const HomeLotteryMix = ({list = [], serverTime}: LotteryMixProps) => {
  const i18n = useTranslation();
  const {width} = useResponsiveDimensions();
  const [targetList, setTargetList] = React.useState<
    (HomeMixDetail & {sellAmount: string})[][]
  >([]);

  React.useEffect(() => {
    const digitlist = list.map(v => {
      const lottoInfo = getUrlParams(v.gameUrl) as {
        lotteryId: string;
        wonAmount: `${number}`;
        gameType?: string;
      };
      const otherInfo = getUrlParams(v.otherParams) as {
        roundNo: string;
        stopTime: `${number}`;
        cycle?: string;
      };
      const isQuick = lottoInfo.gameType === 'quick';
      const isClosed = parseInt(otherInfo.stopTime, 10) * 1000 < serverTime!;
      const remain = otherInfo.cycle
        ? getTimer(serverTime!, +otherInfo.cycle)
        : Math.floor(
            (parseInt(otherInfo.stopTime, 10) * 1000 - (serverTime || 0)) /
              1000,
          );
      return {
        ...v,
        lotteryId: lottoInfo.lotteryId,
        sellAmount: toPriceStr(v.gamePrice as number),
        winAmount: lottoInfo.wonAmount,
        isQuick,
        drawTime: parseInt(otherInfo.stopTime, 10) * 1000,
        remain,
        isClosed,
        cycle: otherInfo.cycle ? +otherInfo.cycle : 0,
        pickBackImg: '',
        result: ['-', '-', '-'],
      };
    });
    const isClosed = digitlist.filter(item => item.isClosed && !item.isQuick);
    const final = digitlist
      .filter(item => !item.isClosed || item.isQuick)
      .sort((a, b) => {
        if (b.isQuick) {
          return 1;
        }
        return a.drawTime - b.drawTime;
      })
      .concat(isClosed);

    if (final.length * 140 > width * 1.5) {
      const halfNum = Math.ceil(final.length / 2);
      const arr1 = final;
      const arr2 = final.splice(halfNum);
      setTargetList(arr1.map((v, i) => [v, arr2[i]]));
    } else {
      setTargetList(final.map(v => [v]));
    }
  }, [list, width, serverTime]);

  const calc = width / 375;
  const imageSize = theme.imageSize.m * calc;
  const logoSize = 56 * calc;

  return (
    <HomeFloorBox
      // title={i18n.t('home.digit.title')}
      title={'State Lottery'}
      list={targetList}
      color="#D9DFED"
      hasRightPadding={false}
      rightDom={<UserPlay number={getNumberRates('MIX')} />}
      renderItem={(arr: SafeAny[], index: number) => {
        return (
          <View key={index}>
            {arr?.map((item, itemIndex) => {
              return item === null || item === undefined ? (
                <View key={index + '' + itemIndex} />
              ) : (
                <ExposureWrap
                  key={index + '' + itemIndex}
                  message={`HOME_MIX_${index}_EXPOSURE`}>
                  <NativeTouchableOpacity
                    style={[
                      {
                        // height: (140 / 375) * width,
                        width: (159 / 375) * width,
                      },
                      theme.background.palegrey,
                      theme.borderRadius.m,
                      theme.overflow.hidden,
                      theme.position.rel,
                      itemIndex !== 0 && theme.margin.topxs,
                      index !== 0 && theme.margin.leftxs,
                    ]}
                    disabled={!item}
                    onPress={() => {
                      trackClick(`HOME_MIX_${index}_TAB`);
                      if (!serverTime) {
                        return globalStore.globalWaringTotal(
                          i18n.t('warning.initializin'),
                        );
                      }
                      if (item.isClosed && !item.isQuick) {
                        globalStore.globalWaringTotal(
                          i18n.t('home.tip.closed', {name: `${item.name}`}),
                        );
                        return;
                      }
                      goTo('MixLottery', {
                        lotteryID: item.lotteryId,
                        isQuick: item.isQuick ? '1' : '0',
                        // pickName: item.pickName,
                        // pickGameType: item.cycle ? 'quick' : 'normal',
                        // cycle: item.cycle,
                      });
                    }}
                    key={index + '' + itemIndex}>
                    <View
                      style={[
                        // eslint-disable-next-line react-native/no-inline-styles
                        {
                          height: 106 * calc,
                          width: 159 * calc,
                          backgroundColor: '#EA8120',
                        },
                      ]}>
                      <View
                        style={[
                          theme.flex.between,
                          theme.flex.flex1,
                          theme.padding.lrs,
                          // eslint-disable-next-line react-native/no-inline-styles
                          {
                            paddingVertical: 12 * calc,
                          },
                        ]}>
                        <View
                          style={[
                            theme.flex.row,
                            theme.flex.between,
                            theme.flex.centerByCol,
                          ]}>
                          <View style={[theme.flex.flex1]}>
                            <Text
                              calc
                              blod
                              numberOfLines={1}
                              color={theme.basicColor.white}
                              fontSize={10}>
                              {item.name}
                            </Text>
                            <Text
                              calc
                              style={[
                                // eslint-disable-next-line react-native/no-inline-styles
                                {
                                  fontStyle: 'italic',
                                  lineHeight: globalStore.isWeb ? 12 : 12,
                                  marginBottom: 2,
                                  marginTop: 4,
                                  height: 12,
                                },
                              ]}
                              textAlign="left"
                              fontSize={9}
                              fontFamily="fontDinBold"
                              color={'#FFE939'}>
                              {'WIN PRIZE'}
                            </Text>
                            <Price
                              textProps={{
                                calc: true,
                                size: 'large',
                                color: theme.basicColor.white,
                              }}
                              price={+item.winAmount}
                              fixed={0}
                            />
                          </View>
                          <View
                            style={[
                              theme.position.abs,
                              theme.margin.leftxxl,
                              theme.fill.fill,
                              theme.margin.topl,
                              // eslint-disable-next-line react-native/no-inline-styles
                              {
                                top: 0,
                                bottom: (28 / 375) * width,
                              },
                              theme.flex.center,
                              theme.padding.tops,
                            ]}>
                            <Image
                              source={require('@assets/imgs/home/lottery-img-light-bg.webp')}
                              style={[
                                // eslint-disable-next-line react-native/no-inline-styles
                                {
                                  width: (104 / 200) * width,
                                  height: (137 / 300) * width,
                                  left: 20,
                                },
                              ]}
                            />
                          </View>
                          <LazyImage
                            width={logoSize}
                            height={logoSize}
                            imageUrl={
                              !item.isClosed
                                ? item.gamePic
                                : item.otherUrl || item.gamePic
                            }
                          />
                        </View>
                        {/* {serverTime && !item.isClosed && (
                        <View
                          style={[
                            theme.margin.btms,
                            theme.flex.row,
                            theme.flex.centerByCol,
                          ]}>
                          <ClockIcon />
                          <Text
                            calc
                            fontSize={12}
                            fontFamily="fontDin"
                            fontWeight="500"
                            color={theme.basicColor.white}>
                            {item.isQuick
                              ? `  TYPE：${item.cycle}minutes`
                              : formatDate(item.drawTime, '  Mon dd hh:mm')}
                          </Text>
                        </View>
                      )} */}
                        {serverTime && (!item.isClosed || item.cycle) ? (
                          <View
                            style={[
                              theme.margin.tops,
                              theme.flex.row,
                              theme.flex.between,
                              theme.flex.centerByCol,
                            ]}>
                            <Text
                              calc
                              fontSize={9}
                              color={theme.basicColor.white}>
                              {item.isQuick
                                ? i18n.t('home.car.text')
                                : dayjs(item.drawTime).format('MMM DD hh:mmA')}
                            </Text>
                            <CountDown
                              resetTime={item.cycle ? item.cycle * 60 : 0}
                              serverTime={serverTime}
                              remain={item.remain}
                            />
                          </View>
                        ) : null}
                      </View>
                    </View>
                    <View
                      style={[
                        theme.flex.flex1,
                        theme.flex.row,
                        theme.padding.tbxxs,
                        theme.flex.centerByCol,
                        theme.flex.between,
                        theme.padding.lrs,
                        theme.background.white,
                      ]}>
                      <View>
                        <Text
                          accent
                          style={[
                            // eslint-disable-next-line react-native/no-inline-styles
                            {
                              lineHeight: 12,
                            },
                          ]}
                          fontSize={10}>
                          {i18n.t('home.digit.price')}:
                        </Text>
                        <Text
                          blod
                          color={theme.basicColor.dark}
                          style={
                            // eslint-disable-next-line react-native/no-inline-styles
                            {
                              lineHeight: 15,
                            }
                          }>
                          {item.sellAmount}
                        </Text>
                      </View>
                      <View
                        style={[
                          theme.padding.tbxxs,
                          theme.padding.lrl,
                          theme.borderRadius.xl,
                          theme.border.secAccent,
                          // eslint-disable-next-line react-native/no-inline-styles
                          {
                            borderWidth: 0.5,
                            paddingVertical: 3.5,
                          },
                        ]}>
                        <Text blod style={[{lineHeight: theme.fontSize.m}]}>
                          Play
                        </Text>
                      </View>
                    </View>
                    {item.isClosed && !item.isQuick && (
                      <View
                        style={[
                          theme.fill.fill,
                          theme.position.abs,
                          {
                            backgroundColor: theme.basicColor.white + 'fff49',
                          },
                        ]}>
                        <Image
                          source={require('@assets/icons/home/closed-gray.webp')}
                          style={[
                            theme.position.abs,
                            {
                              right: theme.paddingSize.s,
                              top: theme.paddingSize.s,
                              width: imageSize,
                              height: imageSize,
                            },
                          ]}
                        />
                      </View>
                    )}
                  </NativeTouchableOpacity>
                </ExposureWrap>
              );
            })}
          </View>
        );
      }}
    />
  );
};

export default React.memo(HomeLotteryMix);
