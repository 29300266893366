import theme from '@/style';
import React from 'react';
import {ImageURISource, View, ScrollView} from 'react-native';
import HomeFloorHeader from './home-floor-header';

interface HomeFloorBoxProps<T> {
  /** 数据列表 */
  list: T[];
  /** 渲染item,会返回数据列表的项,以及index */
  renderItem: (item: T, index: number) => React.ReactElement;
  title: string;
  titleIcon?: ImageURISource;
  titleIconSize?: number;
  rightDom?: React.ReactElement;
  hasRightPadding?: boolean;
  color: string;
}

const HomeFloorBox = <T,>({
  list,
  renderItem,
  titleIcon = require('@assets/icons/home/little.webp'),
  title,
  titleIconSize,
  rightDom,
  hasRightPadding = true,
  color,
}: HomeFloorBoxProps<T>) => {
  return (
    <View style={[theme.fill.fillW, theme.flex.col]}>
      <HomeFloorHeader
        color={color}
        title={title}
        titleIcon={titleIcon}
        titleIconSize={titleIconSize}
        showRightDom
        hasRightPadding={hasRightPadding}
        rightDom={rightDom}
      />
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={[
          theme.margin.lrl,
          theme.margin.btml,
          theme.flex.row,
          theme.flex.wrap,
        ]}>
        {list.map((item, index) => renderItem(item, index))}
      </ScrollView>
    </View>
  );
};

export default HomeFloorBox;
