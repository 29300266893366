/* eslint-disable react-native/no-inline-styles */
import LinearGradient from '@/components/basic/linear-gradient';
import Text from '@/components/basic/text';
import {flex, fontColor, margin} from '@/components/style';
import theme from '@/style';
import React from 'react';
import {ImageBackground, View, Image, ImageURISource} from 'react-native';

const HomeFloorHeader = ({
  title,
  titleIcon = require('@assets/icons/home/little.webp'),
  titleIconSize,
  showRightDom = false,
  hasRightPadding = true,
  rightDom,
  color,
  peopleNumber,
}: {
  title: string;
  titleIcon?: ImageURISource;
  titleIconSize?: number;
  showRightDom?: boolean;
  hasRightPadding?: boolean;
  rightDom?: React.ReactElement;
  color: string;
  peopleNumber?: number | string;
}) => {
  return (
    <View
      style={[
        {
          height: 40,
        },
        theme.borderRadius.l,
        theme.overflow.hidden,
        theme.flex.row,
        theme.position.rel,
        theme.flex.centerByCol,
        theme.flex.between,
      ]}>
      <View style={[theme.position.abs, theme.fill.fill, theme.padding.leftl]}>
        <LinearGradient
          colors={[color, 'rgba(255, 255, 255, 0.00)']}
          style={[
            theme.flex.flex1,
            theme.flex.row,
            theme.flex.end,
            theme.flex.centerByCol,
            hasRightPadding && theme.padding.rightl,
          ]}>
          {showRightDom && rightDom}
        </LinearGradient>
      </View>
      <ImageBackground
        style={[
          {
            width: 200,
          },
        ]}
        source={require('@assets/imgs/home/floor-bg.webp')}>
        <View
          style={[
            theme.flex.row,
            theme.padding.tbs,
            theme.padding.leftl,
            theme.flex.centerByCol,
            theme.flex.between,
            {
              height: 40,
            },
          ]}>
          <Image
            style={[
              theme.icon.l,
              theme.margin.rights,
              titleIconSize
                ? {width: titleIconSize, height: titleIconSize}
                : {width: theme.paddingSize.s, height: theme.paddingSize.s},
            ]}
            source={titleIcon}
          />
          <Text size="medium" main fontFamily="fontInterBold" calc>
            {title}
          </Text>
          <View style={[theme.flex.flex1]} />
        </View>
      </ImageBackground>
      {peopleNumber && (
        <View style={[margin.rightl, flex.row, flex.center]}>
          <Image
            style={[{width: 14, height: 14}, margin.rightxxs]}
            source={require('@components/assets/imgs/peopleNum.png')}
          />
          <Text fontSize={10} color={fontColor.second} blod calc>
            {peopleNumber} Users Playing
          </Text>
        </View>
      )}
    </View>
  );
};

export default HomeFloorHeader;
