import React from 'react';
import {Image} from 'react-native';
import {useTranslation} from 'react-i18next';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import globalStore from '@/services/global.state';
import {View} from 'react-native';
import {getScratchList} from '../home.service';
import {ScratchListItem} from '../home.type';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import HomeFloorHeader from './home-floor-header';
import {FadeInView} from '@/components/basic/animations';
import {goTo} from '@/utils';
import homeStore from '../home.refresh.service';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
export interface HomeScratchProps {
  hideTitle?: boolean;
  changeLoading?: (loading: boolean) => void;
}

const HomeScratch = ({hideTitle}: HomeScratchProps) => {
  const [list, setList] = React.useState<(ScratchListItem | null)[] | null>(
    Array(9).fill(null),
  );
  const i18n = useTranslation();
  const {screenWidth} = useScreenSize();
  const itemWidth =
    (screenWidth - theme.paddingSize.l * 2 - theme.paddingSize.xs * 2) / 3;
  const getList = () => {
    getScratchList()
      .then(scratchList => {
        setList(scratchList);
      })
      .catch(() => {
        setList(null);
      })
      .finally(() => {
        homeStore.refreshEnd.next();
      });
  };
  React.useEffect(() => {
    getList();
    const sub = homeStore.refresh.subscribe(() => {
      getList();
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return list ? (
    <FadeInView
      style={[
        theme.fill.fillW,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: 'auto',
        },
        theme.margin.btml,
      ]}>
      {!hideTitle && (
        <HomeFloorHeader
          color="rgba(201, 228, 209, 0.50)"
          title={i18n.t('home.tab.scratchOff')}
        />
      )}
      <View
        style={[
          theme.fill.fillW,
          theme.flex.row,
          theme.flex.wrap,
          theme.padding.lrl,
        ]}>
        {list.map((item, index) => {
          return (
            <ExposureWrap
              key={index}
              message={`HOME_SCRATCH_${index + 1}_EXPOSURE`}>
              <NativeTouchableOpacity
                style={[
                  {
                    width: itemWidth,
                  },
                  theme.background.palegrey,
                  theme.borderRadius.m,
                  theme.overflow.hidden,
                  index > 2 && theme.margin.topxs,
                  index % 3 !== 0 && theme.margin.leftxs,
                ]}
                disabled={!item}
                onPress={() => {
                  trackClick(`HOME_SCRATCH_${index + 1}_TAB`);
                  item &&
                    goTo('Scratch', {
                      path: 'detail/' + item.id,
                      hideInnerTitle: '1',
                    });
                }}>
                {item ? (
                  <Image
                    source={{uri: item.cover}}
                    style={[
                      {
                        width: itemWidth,
                        height: itemWidth,
                        borderTopLeftRadius: theme.borderRadiusSize.m,
                        borderTopRightRadius: theme.borderRadiusSize.m,
                      },
                    ]}
                  />
                ) : (
                  <View
                    style={[
                      {
                        height: itemWidth,
                        width: itemWidth,
                      },
                      theme.background.palegrey,
                    ]}
                  />
                )}
                <View
                  style={[
                    theme.padding.lrs,
                    theme.padding.tbxs,
                    theme.background.white,
                  ]}>
                  <View>
                    <Text main fontSize={10} calc>
                      {item?.name || '--'}
                    </Text>
                    <View style={[theme.flex.row, theme.flex.alignEnd]}>
                      <Text blod main calc>
                        {globalStore.currency}
                        {item?.price || '--'}
                      </Text>
                      <Text fontSize={9} second calc>
                        /{i18n.t('home.digit.ticket')}
                      </Text>
                    </View>
                  </View>
                </View>
              </NativeTouchableOpacity>
            </ExposureWrap>
          );
        })}
      </View>
    </FadeInView>
  ) : (
    <></>
  );
};

export default HomeScratch;
