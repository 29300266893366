import React, {useState} from 'react';
import {BasicObject} from '@/types';
import LiveCarousel from '@/common-pages/live-casino/live-casino-carousel';
import {toGame} from '@/common-pages/game-navigate';
import {
  LiveHotGameItem,
  getLiveHotGameList,
} from '@/common-pages/live-casino/live-casino-service';
import './home.css';

const HomeSwiperGame = () => {
  const [hotGameList, setHotGameList] = useState<LiveHotGameItem[]>([]);
  const initGames = async () => {
    try {
      const hot = await getLiveHotGameList();
      setHotGameList(hot);
    } catch (e) {}
  };
  React.useEffect(() => {
    initGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="home-swiper-game">
      <LiveCarousel
        onPress={(info: BasicObject) =>
          toGame({
            source: info.source,
            name: info.name || '',
            id: info.gameId,
            gameUrl: info.gameLink,
            tripartiteUniqueness: info.tripartiteUniqueness,
          })
        }
        bannerList={hotGameList}
      />
    </div>
  );
};

export default HomeSwiperGame;
